@import "../../../styles/common";

.container {
  font-size: 0;
  width: 100%;
  height: 100%;
  background-color: $gray3;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.circle {
  border-radius: 100%;
}

.roundedSquare,
.roundedRectangle {
  border-radius: 8px;
}

.roundedLargeSquare {
  border-radius: 32px;
}

.square {
  border-radius: 0;
}

.outline {
  border: 2px solid $gray23;
}

.roundedFullWidthSquare {
  border-radius: 4px;

  img {
    max-height: 120px;
    object-fit: cover;
  }
}
